import React from 'react'

interface Props {
  size: string;
}

export function SolidHalfStar({ size }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path d="M18.3291 8.41608C18.4125 7.99925 18.079 7.49907 17.6622 7.49907L12.9104 6.83215L10.743 2.49721C10.6596 2.33048 10.5762 2.24712 10.4095 2.16375C9.99268 1.91366 9.49249 2.08039 9.2424 2.49721L7.15829 6.83215L2.40653 7.49907C2.15644 7.49907 1.98971 7.58243 1.90634 7.74916C1.57289 8.08262 1.57289 8.5828 1.90634 8.91626L5.32428 12.2508L4.49064 17.0026C4.49064 17.1693 4.49064 17.3361 4.574 17.5028C4.82409 17.9196 5.32428 18.0863 5.7411 17.8362L9.99268 15.5854L14.2443 17.8362C14.3276 17.9196 14.4943 17.9196 14.6611 17.9196C14.7444 17.9196 14.7444 17.9196 14.8278 17.9196C15.2446 17.8362 15.5781 17.4194 15.4947 16.9192L14.6611 12.1675L18.079 8.8329C18.2457 8.74953 18.3291 8.5828 18.3291 8.41608Z" fill="#EFEFF0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99923 2.04627C9.7011 2.04426 9.41334 2.21232 9.2424 2.49721L7.15829 6.83215L2.40653 7.49907C2.15644 7.49907 1.98971 7.58243 1.90634 7.74916C1.57289 8.08262 1.57289 8.5828 1.90634 8.91626L5.32428 12.2508L4.49064 17.0026C4.49064 17.1693 4.49064 17.3361 4.574 17.5028C4.82409 17.9196 5.32428 18.0863 5.7411 17.8362L9.99268 15.5854L9.99923 15.5889V2.04627Z" fill="#363A45"/>
    </svg>
  )
}
