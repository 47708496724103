import React from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import GoogleLongLogo from 'components/Common/TrustIndicators/GoogleLongLogo'
import { SolidHalfStar } from 'components/Common/TrustIndicators/SolidHalfStar'
import styled from 'styled-components'
import Clickable from 'components/Common/Clickable'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { SolidFourStars } from './SolidFourStars'

const StyledClickable = styled(Clickable)`
  min-width: 50%;

  ${mediaQueryUp.desktop} {
    min-width: unset;
  }
`

const StyledBreakpoint = styled(CSSBreakpoint)`
  display: flex;
`

const LOGO_SIZE = {
  desktop: { height: '20', width: '61' },
  mobile: { height: '18', width: '48' },
}

const GOOGLE_REVIEWS_LINK = 'https://maps.app.goo.gl/zHvLcLjEivMd5k2h6?g_st=ic'

function GoogleTrustIndicator() {
  const logoSize = LOGO_SIZE

  return (
    <StyledClickable href={GOOGLE_REVIEWS_LINK} target="_blank">
      <Group fullHeight direction="vertical-reverse" horizontalAlign="center" verticalAlign="center" gap={4}>
        <Group direction="horizontal" gap={8} verticalAlign="end">
          <StyledBreakpoint max="mobile">
            <GoogleLongLogo width={logoSize.mobile.width} height={logoSize.mobile.height} />
          </StyledBreakpoint>
          <StyledBreakpoint min="tablet">
            <GoogleLongLogo width={logoSize.desktop.width} height={logoSize.desktop.height} />
          </StyledBreakpoint>
          <BodyText colour="neutral-two" variant="medium" wrap="no-wrap">2.6k reviews</BodyText>
        </Group>
        <Group direction="horizontal" gap={8}>
          <Group direction="horizontal" gap={4}>
            <SolidFourStars />
            <CSSBreakpoint max="mobile">
              <SolidHalfStar size="16"/>
            </CSSBreakpoint>
            <CSSBreakpoint min="tablet">
              <SolidHalfStar size="20"/>
            </CSSBreakpoint>
          </Group>
          <Heading variant="heading4">4.7/5</Heading>
        </Group>
      </Group>
    </StyledClickable>
  )
}

export default GoogleTrustIndicator
