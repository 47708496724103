import React, { useContext, useMemo } from 'react'
import { getTrustIndicatorsContent } from './helpers'
import { rem } from 'polished'
import styled, { keyframes } from 'styled-components'
import GeoContext from 'contexts/geoContext'
import { mediaQueryUp } from 'components/utils/breakpoint'
import eases from 'styles/tools/eases'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import cn from 'clsx'
import Group from 'components/utils/Group'

const Container = styled.div`
  overflow: hidden;
`

const ItemTransition = keyframes`
  0% {
    transform: translateX(0);
  }

  42% {
    transform: translateX(0);
  }

  /* Transition to slide 2 */

  50% {
    transform: translateX(calc(-100% - ${rem(16)}));
  }

  92% {
    transform: translateX(calc(-100% - ${rem(16)}));
  }

  /* Transition to slide 1 */

  100% {
    transform: translateX(0);
  }
`

const TrustIndicatorsMobile = styled.div`
  display: grid;
  gap: ${rem(16)};
  overflow: visible;
  animation: ${ItemTransition} 8s infinite ${eases.power2.inOut};
  grid-template-columns: repeat(2, 100%);
  width: 100%;

  ${mediaQueryUp.desktop} {
    animation: none;
    grid-template-columns: unset;
    grid-auto-columns: none;
    grid-auto-flow: column;
  }
`

const TrustIndicatorsDesktop = styled.div`
  display: flex;
  justify-content: space-between;

  &.padding {
    ${mediaQueryUp.desktop} {
      padding: 0px ${rem(100)};
    }
  }
`

const BubbleTransition = keyframes`
  0% {
      width: ${rem(16)};
  }

  42% {
      width: ${rem(16)};
  }

  50% {
      width: ${rem(8)};
  }

  92% {
      width: ${rem(8)};
  }

  100% {
      width: ${rem(16)};
  }
`

const BubbleProgressTransition = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }

  /* Slide across the bubble */

  42% {
    transform: translateX(0%);
    opacity: 1;
  }

  /* Fade out as we change slides */

  50% {
    transform: translateX(0%);
    opacity: 0;
  }

  /* Wait for our turn again */

  92% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(-100%);
    opacity: 1;
  }
`

const CarouselBubbles = styled.div`
  display: flex;
  justify-content: center;
  gap: ${rem(4)};
`

const CarouselBubble = styled.div`
  position: relative;
  height: ${rem(8)};
  width: ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  animation: ${BubbleTransition} 8s infinite;
  border: 1px solid ${props => props.theme.palette.neutral.default.one};
  overflow: hidden;
  transform: translateZ(0);


  &:nth-child(2) {
    animation-delay: 4s;

    &::after {
      animation-delay: 4s;
    }
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-color: ${props => props.theme.palette.neutral.default.one};
    animation: ${BubbleProgressTransition} 8s infinite;
  }
`

function TrustIndicatorSection() {
  const { currentRegionCode } = useContext(GeoContext)
  const trustIndicators = useMemo(() => {
    return getTrustIndicatorsContent(currentRegionCode).filter(trustIndicator => trustIndicator.show)
  }, [currentRegionCode])

  return (
    <Container>
      <CSSBreakpoint max="tablet">
        <VerticalSpacer gap={20}>
          <TrustIndicatorsMobile>
            <Group direction="horizontal" horizontalAlign="center" verticalAlign="center">
              {trustIndicators[0].Component}
              {trustIndicators[1].Component}
            </Group>
            <Group direction="horizontal" horizontalAlign="center" verticalAlign="center">
              {trustIndicators[2].Component}
              {trustIndicators[3]?.Component}
            </Group>
          </TrustIndicatorsMobile>
          <CarouselBubbles>
            <CarouselBubble />
            <CarouselBubble />
          </CarouselBubbles>
        </VerticalSpacer>
      </CSSBreakpoint>
      <CSSBreakpoint min="desktop">
        <TrustIndicatorsDesktop className={cn({ padding: trustIndicators.length < 3 })}>
          {trustIndicators.map(({ Component, key }) => <React.Fragment key={key}>{Component}</React.Fragment>)}
        </TrustIndicatorsDesktop>
      </CSSBreakpoint>
    </Container>
  )
}

export default React.memo(TrustIndicatorSection)
