import SolidStarIcon from 'components/Luxkit/Icons/solid/SolidStarIcon'
import React from 'react'

const keys = ['star-1', 'star-2', 'star-3', 'star-4']

export function SolidFourStars() {
  return (
    <>
      {keys.map(key => (<SolidStarIcon key={key} size={20}/>))}
    </>
  )
}
