import React from 'react'

interface Props {
  width: string;
}

export function ProductReviewLogo({ width }: Props) {
  return (
    <svg viewBox="0 0 228 66" width={width} xmlns="http://www.w3.org/2000/svg">
      <path d="M154.97 39.7723V39.8119C154.97 40.9802 154.119 41.7723 152.634 41.7723H149.545V37.7921H152.574C154.059 37.7921 154.97 38.4653 154.97 39.7723Z" fill="#023F2C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M162 54L60.354 54L59.1466 49.2575C62.0771 44.7936 63.9613 39.7214 64.6583 34.4618L64.6595 34.4524C64.8514 32.9837 64.9487 31.496 64.9491 30H170L162 54ZM88.297 49L93.0296 35.0396H89.8415L86.9702 44.4257L83.8613 35H81.2475L78.1386 44.4257L75.2673 35.0396H71.9999L76.7326 49H79.3861L82.5148 39.9307L85.6435 49H88.297ZM95.4271 48.901H98.4766V35.0396H95.4271V48.901ZM114.258 48.901H111.663L104.951 40.0891V48.901H101.941V35.0396H104.753L111.248 43.5742V35.0396H114.258V48.901ZM127.308 48.901H129.902V35.0396H126.892V43.5742L120.397 35.0396H117.585V48.901H120.595V40.0891L127.308 48.901ZM143.784 48.901H133.229V35.0396H143.685V37.7525H136.259V40.5643H142.794V43.2772H136.259V46.1881H143.784V48.901ZM154.911 48.901H158.475L155.089 43.9505C156.852 43.297 158.059 41.8911 158.059 39.6535V39.6139C158.059 36.7624 156.099 35.0396 152.832 35.0396H146.495V48.901H149.545V44.4653H151.941L154.911 48.901Z" fill="#023F2C"/>
      <path d="M55.4303 6C57.7706 8.48207 59.7834 11.3516 61.3639 14.5762L61.347 14.5845C63.7859 19.528 64.9504 24.8142 64.9492 30H220L228 6H55.4303Z" fill="#80BA27"/>
      <path d="M211.019 24.9613C208.95 24.9613 206.9 24.2652 205.276 22.7956L207.036 20.6878C208.273 21.6934 209.53 22.3315 211.077 22.3315C212.315 22.3315 213.03 21.8481 213.03 21.0552V21.0166C213.03 20.2624 212.566 19.8564 210.304 19.2956C207.558 18.6188 205.818 17.826 205.818 15.1575V15.1188C205.818 12.663 207.771 11.0387 210.555 11.0387C212.547 11.0387 214.19 11.6575 215.583 12.7597L214.036 15.0028C212.818 14.1713 211.677 13.6685 210.516 13.6685C209.356 13.6685 208.776 14.1713 208.776 14.8481V14.8867C208.776 15.7956 209.356 16.047 211.696 16.6657C214.442 17.4006 215.989 18.3674 215.989 20.7265V20.7652C215.989 23.453 213.939 24.9613 211.019 24.9613Z" fill="#023F2C"/>
      <path d="M191.267 24.768V11.232H196.546C200.8 11.232 203.74 14.1519 203.74 17.9613V18C203.74 21.8094 200.8 24.768 196.546 24.768H191.267ZM196.546 13.9199H194.245V22.0801H196.546C198.963 22.0801 200.626 20.4365 200.626 18.0387V18C200.626 15.6022 198.963 13.9199 196.546 13.9199Z" fill="#023F2C"/>
      <path d="M177.293 24.768V11.232H183.481C185.202 11.232 186.517 11.6961 187.426 12.605C188.199 13.3785 188.586 14.4033 188.586 15.6989V15.7376C188.586 17.9227 187.406 19.2956 185.685 19.9337L188.992 24.768H185.511L182.611 20.4365H180.271V24.768H177.293ZM180.271 17.8066H183.288C184.757 17.8066 185.569 17.0525 185.569 15.8923V15.8536C185.569 14.558 184.699 13.9199 183.23 13.9199H180.271V17.8066Z" fill="#023F2C"/>
      <path d="M160.856 24.768L166.657 11.1354H169.403L175.204 24.768H172.091L170.853 21.732H165.129L163.892 24.768H160.856ZM166.193 19.1022H169.79L167.991 14.7127L166.193 19.1022Z" fill="#023F2C"/>
      <path d="M145.633 24.8646L141.012 11.232H144.202L147.006 20.3978L150.042 11.1934H152.595L155.631 20.3978L158.434 11.232H161.548L156.926 24.8646H154.335L151.28 16.0083L148.225 24.8646H145.633Z" fill="#023F2C"/>
      <path d="M127.356 24.768L133.157 11.1354H135.903L141.704 24.768H138.591L137.353 21.732H131.63L130.392 24.768H127.356ZM132.693 19.1022H136.29L134.491 14.7127L132.693 19.1022Z" fill="#023F2C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M84.128 18.0203C84.128 21.968 86.5088 25 90.1309 25C93.753 25 96.1745 21.9273 96.1745 17.9797C96.1745 14.032 93.7937 11 90.1716 11C86.5495 11 84.128 14.0727 84.128 18.0203ZM87.1804 17.9797C87.1804 15.4815 88.3358 13.6929 90.1207 13.6861C91.9057 13.693 93.0815 15.5425 93.0815 18.0203C93.0815 20.5029 91.942 22.314 90.1513 22.314C88.3606 22.314 87.1804 20.4826 87.1804 17.9797Z" fill="#023F2C"/>
      <path d="M72.1629 13.8285L74.2588 15.5174V15.4767C75.2559 14.2558 75.9681 13.6657 77.0263 13.6657C78.0844 13.6657 78.8373 14.3169 78.8373 15.436C78.8373 16.5552 78.1862 17.3081 76.4972 18.6919L72.0001 22.3953V24.7558H82.0117V22.1919H76.1106L78.3083 20.4826C80.7094 18.6105 81.8693 17.4709 81.8693 15.2122C81.8693 12.689 80.099 11.0203 77.2094 11.0203C74.7879 11.0203 73.5059 12.0174 72.1629 13.8285Z" fill="#023F2C"/>
      <path d="M100.224 15.5174L98.128 13.8285C99.4711 12.0174 100.753 11.0203 103.175 11.0203C106.064 11.0203 107.834 12.689 107.834 15.2122C107.834 17.4709 106.675 18.6105 104.273 20.4826L102.076 22.1919H107.977V24.7558H97.9652V22.3953L102.462 18.6919C104.151 17.3081 104.802 16.5552 104.802 15.436C104.802 14.3169 104.05 13.6657 102.991 13.6657C101.933 13.6657 101.221 14.2558 100.224 15.4767V15.5174Z" fill="#023F2C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M119.311 19.4244V11.1221H116.788L109.361 19.7297L109.849 21.8459H116.462V24.7762H119.311V21.8459H121.122V19.4244H119.311ZM112.861 19.4244L116.462 15.2122V19.4244H112.861Z" fill="#023F2C"/>
      <path d="M56.9249 16.7768C53.2805 9.34138 46.9552 4.10518 39.6664 1.61275C36.5666 0.544565 33.2573 0 29.948 0H29.9061C25.4868 0 21.0046 0.984406 16.7737 3.05794C9.35928 6.72328 4.10213 13.0486 1.6097 20.3165C-0.861789 27.5843 -0.568562 35.7947 3.05489 43.2301L3.15961 43.4186C6.82496 50.7074 13.1084 55.9017 20.3134 58.3732C23.4342 59.4414 26.7225 59.9859 30.0318 59.9859C33.9275 59.9859 37.8652 59.2319 41.6352 57.6401L58 66L53.7832 48.2568C57.0087 44.026 59.0613 39.0202 59.7525 33.8049C60.4856 28.1917 59.6059 22.2434 56.904 16.7768M54.3697 33.0928C53.8042 37.3865 52.1076 41.4917 49.4686 44.9685L47.8768 47.042L48.5261 49.5764L50.1598 55.9855L44.1905 52.8228L41.9285 51.629L39.5617 52.6134C36.5247 53.891 33.3201 54.5403 30.0528 54.5403C27.3509 54.5403 24.6699 54.1004 22.0937 53.2208C15.9359 51.1053 10.9301 46.7488 8.01881 40.9471L7.93503 40.8005C5.06559 34.915 4.6467 28.2755 6.76212 22.0758C8.89849 15.8552 13.3178 10.8285 19.1824 7.91714C22.5755 6.2625 26.2199 5.40376 29.969 5.40376C32.6709 5.40376 35.3518 5.8436 37.928 6.72328C44.1696 8.85965 49.1963 13.279 52.0658 19.1436C54.2021 23.5001 55.019 28.3174 54.3906 33.0719" fill="#80BA27"/>
      <path d="M29.4244 25.6993L13.6739 22.3691C13.6739 22.3691 13.3807 26.6209 14.1556 31.6686C15.0353 37.4075 16.1664 40.235 16.1664 40.235C16.1664 40.235 18.5541 41.5336 23.9159 43.6909C28.3562 45.4712 32.5033 46.1415 32.5033 46.1415L29.4244 25.7203V25.6993Z" fill="#023F2C"/>
      <path d="M45.5938 26.7885C44.7142 20.5679 43.248 17.845 43.248 17.845L29.4244 25.6993L32.5452 46.1624C32.5452 46.1624 36.2524 44.3402 40.4833 41.1985C45.2797 37.6379 46.2431 36.465 46.2431 36.465C46.2431 36.465 46.4526 32.6949 45.5938 26.8094" fill="#A3D783"/>
      <path d="M43.248 17.8241C43.248 17.8241 40.7346 16.8816 35.7288 15.8762C30.7649 14.8709 27.7279 15.0384 27.7279 15.0384C27.7279 15.0384 24.7328 15.6668 20.2297 18.2639C15.9569 20.7145 13.674 22.3691 13.674 22.3691L29.4244 25.6993L43.248 17.845V17.8241Z" fill="#80BA27"/>
    </svg>
  )
}
