import React from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'

const Container = styled(Group)`
  min-width: 50%;
  height: 100%;

  ${mediaQueryUp.desktop} {
    min-width: unset;
    height: unset;
  }
`

const CONTENT = {
  heading: '8 million members',
  body: 'Trusted by',
}

function StatisticTrustIndicator() {
  const { heading, body } = CONTENT

  return (
    <Container direction="vertical-reverse" horizontalAlign="center" verticalAlign="center" gap={4}>
      <Heading variant="heading3" align="center">{heading}</Heading>
      <BodyText colour="neutral-two" variant="medium" align="center">{body}</BodyText>
    </Container>
  )
}

export default StatisticTrustIndicator
