import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Image from 'components/Common/Image/Image'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'

const Container = styled(Group)`
  min-width: 50%;

  ${mediaQueryUp.desktop} {
    min-width: unset;
  }
`

const ImageContainer = styled.div`
  height: ${rem(32)};
  width: ${rem(32)};
`

function AtolTrustIndicator() {
  return (
    <Container direction="vertical" horizontalAlign="center" verticalAlign="center">
      <ImageContainer>
        <Image id="rwjfqolr1d2jja265gdj" fit="center" dpr={2}/>
      </ImageContainer>
      <BodyText variant="medium" colour="neutral-two">ATOL protected</BodyText>
    </Container>
  )
}

export default AtolTrustIndicator
