import React from 'react'
import GoogleTrustIndicator from './GoogleTrustIndicator'
import TrustPilotTrustIndicator from './TrustPilotTrustIndicator'
import StatisticTrustIndicator from './StatisticTrustIndicator'
import AtolTrustIndicator from './AtolTrustIndicator'
import ProductReviewTrustIndicator from './ProductReviewTrustIndicator'
import { ATOL_REGIONS, PRODUCT_REVIEW_REGIONS } from 'constants/config/region'

export function getTrustIndicatorsContent(regionCode: string) {
  return ([
    {
      key: 'google',
      Component: <GoogleTrustIndicator />,
      show: true,
    },
    {
      key: 'trust-pilot',
      Component: <TrustPilotTrustIndicator />,
      show: true,
    },
    {
      key: 'product-review',
      Component: <ProductReviewTrustIndicator />,
      show: PRODUCT_REVIEW_REGIONS.includes(regionCode),
    },
    {
      key: 'statistic',
      Component: <StatisticTrustIndicator />,
      show: true,
    },
    {
      key: 'atol',
      Component: <AtolTrustIndicator />,
      show: ATOL_REGIONS.includes(regionCode),
    },
  ])
}
