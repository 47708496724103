import React from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { TrustPilotStars } from 'components/Common/TrustIndicators/TrustPilotStars'
import { TrustPilotLogo } from 'components/Common/TrustIndicators/TrustPilotLogo'
import styled from 'styled-components'
import Clickable from 'components/Common/Clickable'
import { mediaQueryUp } from 'components/utils/breakpoint'

const StyledClickable = styled(Clickable)`
  min-width: 50%;

  ${mediaQueryUp.desktop} {
    min-width: unset;
  }
`

const StyledBreakpoint = styled(CSSBreakpoint)`
  display: flex;
`

const TRUST_PILOT_LINK = 'https://trustpilot.com/review/luxuryescapes.com'

const LOGO_SIZE = {
  desktop: { height: '20', width: '83' },
  mobile: { height: '18', width: '66' },
}

function TrustPilotTrustIndicator() {
  const logoSize = LOGO_SIZE

  return (
    <StyledClickable href={TRUST_PILOT_LINK} target="_blank">
      <Group fullHeight direction="vertical" horizontalAlign="center" verticalAlign="center" gap={4}>
        <Group direction="horizontal" gap={12} verticalAlign="center">
          <Heading variant="heading4">'Excellent'</Heading>
          <CSSBreakpoint max="mobile">
            <TrustPilotStars width="65" height="12" />
          </CSSBreakpoint>
          <StyledBreakpoint min="tablet">
            <TrustPilotStars width="108" height="20" />
          </StyledBreakpoint>
        </Group>
        <Group direction="horizontal" gap={12} verticalAlign="end">
          <CSSBreakpoint max="mobile">
            <TrustPilotLogo width={logoSize.mobile.width} height={logoSize.mobile.height} />
          </CSSBreakpoint>
          <CSSBreakpoint min="tablet">
            <TrustPilotLogo width={logoSize.desktop.width} height={logoSize.desktop.height} />
          </CSSBreakpoint>
          <BodyText colour="neutral-two" variant="medium" wrap="no-wrap">8k reviews</BodyText>
        </Group>
      </Group>
    </StyledClickable>
  )
}

export default TrustPilotTrustIndicator
