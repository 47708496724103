import React from 'react'
import styled from 'styled-components'
import Clickable from 'components/Common/Clickable'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { ProductReviewLogo } from 'components/Common/TrustIndicators/ProductReviewLogo'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const StyledClickable = styled(Clickable)`
  min-width: 50%;
  width: ${rem(204)};

  ${mediaQueryUp.desktop} {
    min-width: unset;
    width: ${rem(240)};
  }
`

const PRODUCT_REVIEW_LINK = 'https://www.productreview.com.au/listings/luxury-escapes'

const LOGO_SIZE = {
  desktop: { width: '240' },
  mobile: { width: '165' },
}

function ProductReviewTrustIndicator() {
  const logoSize = LOGO_SIZE

  return (
    <StyledClickable href={PRODUCT_REVIEW_LINK} target="_blank">
      <Group direction="horizontal" horizontalAlign="center" verticalAlign="center">
        <CSSBreakpoint max="mobile">
          <ProductReviewLogo width={logoSize.mobile.width} />
        </CSSBreakpoint>
        <CSSBreakpoint min="tablet">
          <ProductReviewLogo width={logoSize.desktop.width} />
        </CSSBreakpoint>
      </Group>
    </StyledClickable>
  )
}

export default ProductReviewTrustIndicator
