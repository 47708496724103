import React from 'react'

interface Props {
  width: string;
  height: string;
}

export function TrustPilotStars({ width, height }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 108 20" fill="none">
      <g clipPath="url(#clip0_3092_174509)">
        <path d="M20.5449 0H0.544922V20H20.5449V0Z" fill="#00B67A"/>
        <path d="M42.2109 0H22.2109V20H42.2109V0Z" fill="#00B67A"/>
        <path d="M63.8789 0H43.8789V20H63.8789V0Z" fill="#00B67A"/>
        <path d="M85.5449 0H65.5449V20H85.5449V0Z" fill="#00B67A"/>
        <path d="M107.211 0H87.2109V20H107.211V0Z" fill="#00B67A"/>
        <path d="M10.5449 13.1042L13.5866 12.3333L14.8574 16.25L10.5449 13.1042ZM17.5449 8.04167H12.1908L10.5449 3L8.89909 8.04167H3.54492L7.87826 11.1667L6.23242 16.2083L10.5658 13.0833L13.2324 11.1667L17.5449 8.04167Z" fill="white"/>
        <path d="M32.2109 13.4792L35.2526 12.7083L36.5234 16.625L32.2109 13.4792ZM39.2109 8.41667H33.8568L32.2109 3.375L30.5651 8.41667H25.2109L29.5443 11.5417L27.8984 16.5833L32.2318 13.4583L34.8984 11.5417L39.2109 8.41667Z" fill="white"/>
        <path d="M53.8789 13.4792L56.9206 12.7083L58.1914 16.625L53.8789 13.4792ZM60.8789 8.41667H55.5247L53.8789 3.375L52.2331 8.41667H46.8789L51.2122 11.5417L49.5664 16.5833L53.8997 13.4583L56.5664 11.5417L60.8789 8.41667Z" fill="white"/>
        <path d="M75.5449 13.4792L78.5866 12.7083L79.8574 16.625L75.5449 13.4792ZM82.5449 8.41667H77.1908L75.5449 3.375L73.8991 8.41667H68.5449L72.8783 11.5417L71.2324 16.5833L75.5658 13.4583L78.2324 11.5417L82.5449 8.41667Z" fill="white"/>
        <path d="M97.2109 13.4792L100.253 12.7083L101.523 16.625L97.2109 13.4792ZM104.211 8.41667H98.8568L97.2109 3.375L95.5651 8.41667H90.2109L94.5443 11.5417L92.8984 16.5833L97.2318 13.4583L99.8984 11.5417L104.211 8.41667Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_3092_174509">
          <rect width="106.667" height="20" fill="white" transform="translate(0.544922)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
